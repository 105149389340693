<template>

    <!-- Contact Form & Map Overlay Section
    ============================================= -->
    <section id="map-overlay" style="margin-bottom: 50px;">
        <div class="container">

            <div class="row">
                <!-- Contact Form Overlay
                ============================================= -->
                <div class="contact-form-overlay col-md-6 offset-md-6 col-lg-4 offset-lg-8 p-5">

                    <div class="fancy-title title-border">
                        <h3>Send us an Email</h3>
                    </div>

                    <div class="form-widget" data-alert-type="inline">

                        <div class="form-result"></div>

                        <!-- Contact Form
                        ============================================= -->
                        <form class="row mb-0" id="template-contactform" name="template-contactform" v-on:submit.prevent="send()" >

                            <div class="col-12 form-group">
                                <label for="template-contactform-name">Name <small>*</small></label>
                                <input type="text" id="template-contactform-name" name="template-contactform-name" value="" class="sm-form-control required" />
                            </div>

                            <div class="col-12 form-group">
                                <label for="template-contactform-email">Email <small>*</small></label>
                                <input type="email" id="template-contactform-email" name="template-contactform-email" value="" class="required email sm-form-control" />
                            </div>

                            <div class="col-12 form-group">
                                <label for="template-contactform-phone">Phone</label>
                                <input type="text" id="template-contactform-phone" name="template-contactform-phone" value="" class="sm-form-control" />
                            </div>
                            <div class="col-12 form-group">
                                <label for="template-contactform-subject">Country <small>*</small></label>
                                <input type="text" id="template-contactform-subject" name="subject" value="" class="required sm-form-control" />
                            </div>

                            <div class="col-12 form-group">
                                <label for="template-contactform-message">Message <small>*</small></label>
                                <textarea class="required sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30"></textarea>
                            </div>

                            <div class="col-12 form-group d-none">
                                <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" value="" class="sm-form-control" />
                            </div>

                            <div v-if="button" class="col-12 form-group">
                                <button  class="button button-3d m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Send Message</button>
                            </div>

                            <div v-else class="col-12">

                                <div v-if="loading" class="alert alert-warning instagram-widget-alert text-center">
                                    <div class="spinner-grow spinner-grow-sm mr-2" role="status">
                                        <span class="sr-only">Sending...</span></div>
                                    Sending.....</div>
                                <div v-else-if="success" class="alert alert-success">
                                    <i class="icon-gift"></i><strong>Well done!</strong> The mail has been sent successfully
                                </div>
                            </div>
                            <input type="hidden" name="prefix" value="template-contactform-">

                        </form>

                    </div>
                    <div class="line"></div>
                    <div class="row col-mb-50">
                        <!-- Contact Info
                        ============================================= -->
                        <div class="col-md-12">
                            <address>
                                <strong>Headquarters:</strong><br>
                                Moldova, mun. Chisinau, <br>
                                str. Vlaicu Pârcălab 63<br>
                            </address>
                            <abbr title="Email Address"><strong>Email:</strong></abbr> <a href="mailto:craftytechceo@yahoo.com">craftytechceo@yahoo.com</a>
                        </div><!-- Contact Info End -->

                    </div>

                </div><!-- Contact Form Overlay End -->
            </div>

        </div>


        <section class="gmap" > </section>
    </section><!-- Contact Form & Map Overlay Section End -->

</template>

<script>

    import axios from 'axios';
    export default {
        name: "Contact",

        data() {
            return {
                marker: '[{latitude:47.023501, longitude:28.835964, html: "", icon:{ image: "' + require('@/assets/images/icons/map-icon-red.png') + '", iconsize: [32, 39], iconanchor: [32,39] } }]',
                loading:false,
                success:false,
                button: true,
            }
        },

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Contact',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        methods:{
            send(){
                if (  $('form#template-contactform').valid() ) {
                    let formData=    $('form#template-contactform').serializeObject();
                    this.button = false;
                    this.loading = true;
                    axios.post(`http://craftytech.develoapper.xyz/sendmail3.php`, {
                        body: formData
                    })
                        .then(response => {
                            console.log(response)
                            this.loading = false;
                            this.success = true;

                            $("form#template-contactform").trigger('reset');
                        })
                        .catch(e => {
                            this.errors.push(e)
                        })
                }

            }
        },
        mounted: function () {
            window.$ = window.jQuery;
            $.fn.serializeObject = function () {
                var arrayData, objectData;
                arrayData = this.serializeArray();
                objectData = {};

                $.each(arrayData, function () {
                    var value;

                    if (this.value != null) {
                        value = this.value;
                    } else {
                        value = '';
                    }

                    if (objectData[this.name] != null) {
                        if (!objectData[this.name].push) {
                            objectData[this.name] = [objectData[this.name]];
                        }

                        objectData[this.name].push(value);
                    } else {
                        objectData[this.name] = value;
                    }
                });

                return objectData;
            };

        }
    }
</script>

<style scoped>
    #map-overlay .gmap {
        background: url("../../assets/images/contact-bg.png") no-repeat center;
        background-size: cover;

    }

</style>